@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  background-color: #f5f5f5;
}


@layer base {

  ul,
  ol {
    list-style: revert;
    padding-left: 20px;
  }
}

.ql-syntax {
  /* Add your desired styles here */
  /* Example styles for syntax highlighting */
  background-color: black;
  padding: 10px;
  border-radius: 4px;
  color: white;
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
}

blockquote {
  background-color: #f7fafc;
  border-left: 4px solid #cbd5e0;
  padding: 1rem;
  margin: 1rem 0;
}

.ql-font-monospace {
  font-family: monospace;
}

a {
  color: green;
  text-decoration: underline;
}

.ql-align-center {
  text-align: center;
}

.ql-align-justify {
  text-align: justify;
}

.ql-align-left {
  text-align: left;
}

.ql-align-right {
  text-align: right;
}


.ql-size-large {
  font-size: 40px;
  line-height: 1.5;
}

.ql-size-huge {
  font-size: 60px;
  line-height: 1.5;
}


.ql-editor.ql-blank {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 3px;
}

.ql-editor.ql-blank[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: #999;
  font-style: normal;
  border-radius: 3px;
}

.ql-editor.ql-blank p {
  margin: 0;
}


/* new */

.ql-toolbar.ql-snow {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  border: 2px solid #ccc;
  padding: 10px;

  border-radius: 3px;

  padding-left: 20px;


}


.ql-editor {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 3px;
}



@media (min-width: 768px) {
  .ql-toolbar.ql-snow>*+* {
    margin-left: 10px;
  }
}


.no-bullet {
  list-style: none;
  list-style-type: none;
  padding-left: 0;
}